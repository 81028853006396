import Section from '@/components/ui/atoms/Section'
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
} from '@/components/ui/atoms/brand'
import { cn } from '@/lib/utils'
import { useStore } from '@nanostores/react'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { atom } from 'nanostores'

const seeMoreAtom = atom<boolean>(false)

export default function Footer() {
  const $seeMore = useStore(seeMoreAtom)

  const navigation: {
    title: string
    href?: string
    children?: Array<{ title: string; href: string; type?: string }>
  }[] = [
    {
      title: 'Institucional',
      children: [
        { title: 'Quem somos', href: '/sobre' },
        { title: 'Cursos', href: '/cursos' },
        { title: 'Concursos', href: '/concursos' },
        { title: 'Trabalhe conosco', href: '/trabalhe-conosco' },
        {
          title: 'Política de privacidade',
          href: '/politicas-de-privacidade',
        },
        { title: 'Termos de uso', href: '/termos-de-uso' },
      ],
    },
    {
      title: 'Suporte',
      children: [
        { title: 'Cursos por concurso', href: '/concursos' },
        { title: 'Perguntas Frequentes', href: '/faq' },
        { title: 'Assinaturas', href: '/assinaturas' },
        { title: 'Fale conosco', href: '/contato' },
      ],
    },
    {
      title: 'Concursos',
      children: [
        { title: 'Concursos abertos', href: '/concursos-abertos/' },
        {
          title: 'Concursos nivel médio',
          href: '/noticias/concurso-nivel-medio/',
        },
        { title: 'Concursos 2024', href: '/concursos-2024/' },
        { title: 'Concursos 2025', href: '/concursos-2025/' },
      ],
    },
    {
      title: 'Principais Concursos',
      children: [
        {
          title: 'Concurso Receita Federal',
          href: '/noticias/concurso-receita-federal/',
        },
        { title: 'Concurso INSS', href: '/noticias/concurso-inss/' },
        {
          title: 'Concurso Banco do Brasil',
          href: '/noticias/concurso-banco-do-brasil/',
        },
        {
          title: 'Concurso Senado',
          href: '/noticias/concurso-senado/',
        },
        {
          title: 'Concurso PRF (Polícia Rodoviária Federal)',
          href: '/noticias/concurso-prf/',
        },
        {
          title: 'Concurso PF (Polícia Federal)',
          href: '/noticias/concurso-pf/',
        },
        { title: 'Concurso TJSP', href: '/noticias/concurso-tj-sp/' },
        { title: 'Concurso TCU', href: '/noticias/concurso-tcu/' },
        { title: 'Concurso CGU', href: '/noticias/concurso-cgu/' },
        { title: 'Concurso MPU', href: '/noticias/concurso-mpu/' },
        {
          title: 'Concurso IBGE - Efetivos',
          href: '/noticias/concurso-ibge/',
        },
        {
          title: 'Concurso Caixa Econômica Federal',
          href: '/noticias/concurso-caixa/',
        },
        {
          title: 'Concurso Câmara dos Deputados',
          href: '/noticias/concurso-camara-dos-deputados/',
        },
        { title: 'Concurso Bacen', href: '/noticias/concurso-bacen/' },
        { title: 'Concurso AFT', href: '/noticias/concurso-aft/' },
      ],
    },
    {
      title: 'Concursos por localização',
      children: [
        {
          title: 'Concursos no Sudeste',
          href: '/noticias/concurso-publico-sudeste-2023/',
          type: 'subtitle',
        },
        {
          title: 'Espírito Santo',
          href: '/noticias/concurso-publico-espirito-santo-2023',
        },
        {
          title: 'Minas Gerais',
          href: '/noticias/concurso-publico-minas-gerais-2023',
        },
        {
          title: 'Rio de Janeiro',
          href: '/noticias/concurso-publico-rio-de-janeiro-2023/',
        },
        {
          title: 'São Paulo',
          href: '/noticias/concurso-publico-sao-paulo-2023/',
        },
        {
          title: 'Concursos no Nordeste',
          href: '/noticias/concursos-nordeste-2022/',
          type: 'subtitle',
        },
        {
          title: 'Alagoas',
          href: '/noticias/concursos-nordeste-2022/',
        },
        { title: 'Bahia', href: '/noticias/concursos-nordeste-2022/' },
        { title: 'Ceará', href: '/noticias/concursos-nordeste-2022/' },
        {
          title: 'Maranhão',
          href: '/noticias/concursos-nordeste-2022/',
        },
        {
          title: 'Paraíba',
          href: '/noticias/concursos-nordeste-2022/',
        },
        {
          title: 'Pernambuco',
          href: '/noticias/concursos-nordeste-2022/',
        },
        { title: 'Piauí', href: '/noticias/concursos-nordeste-2022/' },
        {
          title: 'Rio Grande do Norte',
          href: '/noticias/concursos-nordeste-2022/',
        },
        {
          title: 'Sergipe',
          href: '/noticias/concursos-nordeste-2022/',
        },
        {
          title: 'Concursos no Norte',
          href: '/noticias/concurso-publico-norte-2023/',
          type: 'subtitle',
        },
        {
          title: 'Acre',
          href: '/noticias/concurso-publico-acre-2023/',
        },
        {
          title: 'Amapá',
          href: '/noticias/concurso-publico-amapa-2023',
        },
        {
          title: 'Amazonas',
          href: '/noticias/concurso-publico-amazonas-2023',
        },
        { title: 'Pará', href: '/noticias/concurso-publico-para-2023' },
        {
          title: 'Rondônia',
          href: '/noticias/concurso-publico-rondonia-2023',
        },
        {
          title: 'Roraima',
          href: '/noticias/concurso-publico-roraima-2023',
        },
        {
          title: 'Tocantins',
          href: '/noticias/concurso-publico-tocantins-2023',
        },
        {
          title: 'Concursos no Sul',
          href: '/noticias/concurso-publico-norte-2023/',
          type: 'subtitle',
        },
        {
          title: 'Paraná',
          href: '/noticias/concurso-publico-parana-2023/',
        },
        {
          title: 'Rio Grande do Sul',
          href: '/noticias/concurso-publico-rio-grande-do-sul-2023/',
        },
        {
          title: 'Santa Catarina',
          href: '/noticias/concurso-publico-santa-catarina-2023/',
        },
        {
          title: 'Concursos no Centro Oeste',
          href: '/noticias/concursos-centro-oeste-2023',
          type: 'subtitle',
        },
        {
          title: 'Goiás',
          href: '/noticias/concursos-centro-oeste-2023',
        },
        {
          title: 'Mato Grosso',
          href: '/noticias/concursos-centro-oeste-2023',
        },
        {
          title: 'Mato Grosso do Sul',
          href: '/noticias/concursos-centro-oeste-2023',
        },
        {
          title: 'Distrito Federal',
          href: '/noticias/concursos-centro-oeste-2023',
        },
      ],
    },
    {
      title: 'Concursos por carreira',
      children: [
        {
          title: 'Concursos Policiais',
          href: '/noticias/concurso-policial/',
          type: 'subtitle',
        },
        {
          title: 'Polícia Militar',
          href: '/noticias/concurso-policia-militar/',
        },
        {
          title: 'Polícia Civil',
          href: '/noticias/concurso-policia-civil-outubro-2022/',
        },
        {
          title: 'Polícia Rodoviária Federal',
          href: '/noticias/concurso-prf/',
        },
        { title: 'Polícia Federal', href: '/noticias/concurso-pf/' },
        { title: 'Concurso Depen', href: '/concurso/depen/' },
        {
          title: 'Concursos Policia Penal',
          href: '/noticias/concurso-policia-penal/',
        },
        {
          title: 'Concursos de Tribunais',
          href: '/noticias/concurso-tribunal/',
          type: 'subtitle',
        },
        {
          title: 'Concursos Tribunais de Justiça (TJs)',
          href: '/noticias/concurso-tribunal/',
        },
        {
          title: 'Concursos Tribunais Regionais do Trabalho (TRTs)',
          href: '/noticias/concurso-tribunal/',
        },
        {
          title: 'Concursos Tribunais Regionais Eleitorais (TREs)',
          href: '/noticias/concurso-tribunal/',
        },
        {
          title: 'Concursos Tribunais Regionais Federais (TRFs)',
          href: '/noticias/concurso-tribunal/',
        },
        {
          title: 'Concursos Fiscais',
          href: '/noticias/concurso-fiscal-2023',
          type: 'subtitle',
        },
        {
          title: 'Concursos SEFAZ (Secretarias de Estado)',
          href: '/noticias/concurso-fiscal-2023',
        },
        {
          title: 'Concurso SEFAZ AM',
          href: '/noticias/concurso-sefaz-am/',
        },
        {
          title: 'Concurso SEFAZ MG',
          href: '/noticias/concurso-sefaz-mg/',
        },
        {
          title: 'Concurso SEFAZ BA',
          href: '/noticias/concurso-sefaz-ba/',
        },
        {
          title: 'Concurso SEFAZ CE',
          href: '/noticias/concurso-sefaz-ce/',
        },
        {
          title: 'Concurso SEFAZ MT',
          href: '/noticias/concurso-sefaz-mt/',
        },
        {
          title: 'Concurso SEFAZ PE',
          href: '/noticias/concurso-sefaz-pe/',
        },
        {
          title: 'Concurso SEFAZ ES',
          href: '/noticias/concurso-sefaz-es/',
        },
        {
          title: 'Concurso SEFAZ SC',
          href: '/noticias/concurso-sefaz-sc/',
        },
        {
          title: 'Concurso ISS RJ',
          href: '/noticias/concurso-iss-rj-edital-2022/',
        },
        {
          title: 'Concurso ISS SP',
          href: '/noticias/concurso-iss-sp/',
        },
        {
          title: 'Concursos Legislativos',
          href: '/noticias/concurso-legislativo/',
          type: 'subtitle',
        },
        {
          title: 'Concurso Câmara dos Deputados',
          href: '/noticias/concurso-camara-dos-deputados/',
        },
        {
          title: 'Concurso Senado Federal',
          href: '/noticias/concurso-senado/',
        },
      ],
    },
    {
      title: 'Principais bancas de concurso',
      children: [
        {
          title: 'Cebraspe (antigo Cespe)',
          href: '/noticias/concurso-cebraspe/',
        },
        {
          title: 'FCC - Fundaçãoo Carlos Chagas',
          href: '/noticias/concurso-fcc/',
        },
        {
          title: 'FGV - Fundaçãoo Getúlio Vargas',
          href: '/noticias/concurso-fgv/',
        },
        { title: 'Cesgranrio', href: '/noticias/concurso-cesgranrio/' },
        {
          title: 'Instituto AOCP',
          href: '/noticias/concurso-instituto-aocp/',
        },
        {
          title: 'IBFC',
          href: '/noticias/concurso-publico-ibfc-editais/',
        },
        { title: 'Ibade', href: '/noticias/concurso-ibade/' },
        { title: 'Iades', href: '/noticias/concursos-iades/' },
        { title: 'Quadrix', href: '/noticias/concursos-quadrix/' },
        { title: 'Vunesp', href: '/noticias/concurso-vunesp/' },
        { title: 'Idecan', href: '/noticias/concurso-idecan/' },
        { title: 'Fundatec', href: '/noticias/concurso-fundatec/' },
      ],
    },
  ]

  return (
    <footer>
      <Section id="footer">
        <>
          <div
            className={cn(
              'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-12 text-xs text-gray-500',
              !$seeMore && 'overflow-hidden max-h-[600px]',
            )}
          >
            <div>
              <img
                loading="lazy"
                src="/images/logo-color.webp"
                height={43}
                width={170}
                alt="Direção Concursos - Rodapé"
              />
              <p className="mt-4 text-xs leading-5 font-medium max-w-[250px] text-zinc-400">
                Direção Concursos representa, na atualidade, o que há de melhor
                na preparação em alto rendimento para concursos públicos no
                Brasil.
              </p>
            </div>
            {navigation.map((link) => {
              return (
                <div key={link.title}>
                  <h2 className="font-medium text-base text-orange-600">
                    {link.title}
                  </h2>
                  <ul
                    role="list"
                    aria-labelledby={link.title}
                    className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                  >
                    {link?.children?.map((child) => {
                      return (
                        <li
                          className={cn(
                            'flex',
                            child.type === 'subtitle' &&
                              'font-medium text-gray-900',
                          )}
                          key={child.title}
                        >
                          <a className="hover:text-gray-800" href={child.href}>
                            {child.title}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </div>
          <div
            className={cn(
              'bg-gradient-to-t from-white relative min-h-[150px] -mt-[150px] z-20',
              $seeMore && 'hidden',
            )}
          />
          <button
            type="button"
            onClick={() => seeMoreAtom.set(!$seeMore)}
            className="flex w-full justify-center items-center px-8 py-2 text-xs text-orange-500 font-medium"
          >
            {$seeMore ? (
              <>
                Ver menos
                <ChevronUp
                  className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                  aria-hidden="true"
                />
              </>
            ) : (
              <>
                Ver mais
                <ChevronDown
                  className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                  aria-hidden="true"
                />
              </>
            )}
          </button>
        </>
      </Section>
      <Section
        id="footer-payment-and-social-media"
        className="border-t py-5 sm:py-5 lg:py-5"
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 items-center justify-between">
          <div className="grid col-span-full lg:col-span-2 items-center">
            <div className="flex flex-wrap gap-2 items-center justify-center lg:justify-start">
              <p className="text-xs my-4 text-zinc-600 basis-full text-center lg:basis-auto">
                Formas de pagamento
              </p>
              <div className="px-3 py-2 border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/visa-logo.webp"
                  height={26}
                  width={38}
                  alt="Logo do Direção Concursos"
                />
              </div>
              <div className="px-3 py-2 bg-[#1f72cd] border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/american-express-logo.webp"
                  height={26}
                  width={38}
                  aria-label="Amex"
                  alt="Logo do Direção Concursos"
                />
              </div>
              <div className="px-3 py-2 border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/mastercard-logo.webp"
                  height={26}
                  width={38}
                  alt="Logo do Direção Concursos"
                />
              </div>
              <div className="px-3 py-2 border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/hipercard-logo.webp"
                  height={26}
                  width={38}
                  alt="Logo do Direção Concursos"
                />
              </div>
              <div className="px-3 py-2 border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/elo-logo.webp"
                  height={26}
                  width={38}
                  alt="Logo do Direção Concursos"
                />
              </div>
              <div className="px-3 py-2 border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/boleto-logo.webp"
                  height={26}
                  width={38}
                  alt="Logo do Direção Concursos"
                />
              </div>
              <div className="px-3 py-2 border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/pix-logo.webp"
                  height={26}
                  width={38}
                  alt="Logo do Direção Concursos"
                />
              </div>
            </div>
          </div>
          <div className="flex my-4 items-end justify-center lg:justify-end gap-3">
            <a
              className="text-white bg-sky-700 p-2 rounded-sm"
              target="_blank"
              rel="noreferrer"
              aria-label="LinkedIn"
              href="https://www.linkedin.com/company/direcao-concursos/mycompany/"
            >
              <Linkedin size={16} />
            </a>
            <a
              className="text-white bg-sky-700 p-2 rounded-sm"
              target="_blank"
              rel="noreferrer"
              aria-label="Instagram"
              href="https://www.instagram.com/direcaoconcursos/"
            >
              <Instagram size={16} />
            </a>
            <a
              className="text-white bg-sky-700 p-2 rounded-sm"
              target="_blank"
              rel="noreferrer"
              aria-label="Facebook"
              href="https://www.facebook.com/direcaoconcursos/"
            >
              <Facebook size={16} />
            </a>
            <a
              className="text-white bg-sky-700 p-2 rounded-sm"
              target="_blank"
              rel="noreferrer"
              aria-label="Twitter"
              href="https://twitter.com/direcaoconcurso"
            >
              <Twitter size={16} />
            </a>
            <a
              className="text-white bg-sky-700 p-2 rounded-sm"
              target="_blank"
              rel="noreferrer"
              aria-label="Youtube"
              href="https://www.youtube.com/c/Dire%C3%A7%C3%A3oConcursos"
            >
              <Youtube size={16} />
            </a>
          </div>
        </div>
      </Section>
      <section className="w-full mt-0 mx-auto text-center bg-zinc-800 text-white">
        <p className="text-[.65rem] p-6">
          DIREÇÃO CONCURSOS - CURSOS ONLINE PARA CONCURSOS. TODOS OS DIREITOS
          RESERVADOS. CNPJ: 32.161.525/0001-03
        </p>
      </section>
    </footer>
  )
}
